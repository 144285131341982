html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Plus Jakarta Sans';
}

body {
  background: #FAFAFB;
}

.flex {
  display: flex;
  height: 100%;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.header {
  height: 65px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.05);
}

.card {
  width: 46%;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
}

@media only screen and (max-device-width: 767px) {
  .card {
    width: 90%;
  }
}
.card>div {
  margin: 9% 10% 6% 7%;
  flex: 1;
}


.card>div>div {
  width: 100%;
  color: #3249D7;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.input {
  width: 100%;
  padding: 12px 20px;
  height: 48px;
  margin: 8px 0;
  box-sizing: border-box;
  background: #FAFAFB;
  border: 1px solid #E6E7EA;
  border-radius: 10px;
  color: #010E30;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: inherit;
}
input.checked{
  width: 15px;
  height: 15px;
}
.checkbox{
  margin: 10px 0;
}
.labelcheck{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4D566E;
}
.Tearms{
  padding-left: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3249D7;
}
.button {
  background: #3249D7;
  color: white;
  padding: 14px 20px;
  margin: 8px 0 25px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.button:hover {
  opacity: 0.8;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4D566E;
}
.error {
  font-size: 12px;
  color: red;
  margin: 0 0 27px 10px;
}

.forgot-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.forgot-button>div {
  cursor: pointer;
  width: fit-content;
}
.forgot-button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.google {
  background: white;
  color: #4b465c;
  padding: 14px 20px;
  margin: 8px 0 25px 0;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content img {
  margin-right: 10px; /* Adjust the margin as needed */
}

.forgot-button1 span {
  padding-top: 5px;
}
